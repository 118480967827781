import React from "react";
import { toEm } from "../utils/css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        padding: toEm(20),
        fontSize: toEm(14),
      }}
    >
      {/* <Link to="/EC">Engagement de confidentitalité</Link>
      <div style={{ padding: toEm(10) }}>-</div> */}
      <a href="https://www.youtube.com/watch?v=-CVn3-3g_BI">Lien Youtube</a>
      <div style={{ padding: toEm(10) }}>-</div>
      <div style={{ fontFamily: "riftonnormal" }}>
        <a style={{}} href="https://julienchapus.format.com/">
          J
        </a>
        &<a href="https://jeremydelacasa.com">J</a>
      </div>
    </div>
  );
};

export default Footer;
