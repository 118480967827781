import { useRef, useState, useEffect } from "react";

const useAudio = ({ url, canPlayThrough }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const audio = useRef({});

  useEffect(() => {
    init(url);

    return () => {
      reset();
    };
  }, []);

  const onEnded = () => {
    stop();
  };

  const init = (url) => {
    if (url) {
      setIsLoading(true);
    }
    audio.current = new Audio(url);
    audio.current.title = url;
    audio.current.addEventListener("canplaythrough", () => {
      setIsLoading(false);
      canPlayThrough();
    });
    audio.current.addEventListener("ended", onEnded);
    audio.current.load();
  };

  const setUrl = (url) => {
    init(url);
  };

  const play = () => {
    audio.current.play();
    setIsPlaying(true);
  };

  const reset = () => {
    audio.current.removeEventListener("canplaythrough", canPlayThrough);
    audio.current.removeEventListener("ended", onEnded);
    audio.current = {};
  };

  const stop = () => {
    if (audio.current) {
      audio.current.pause();
    }
    setIsLoading(false);
    setIsPlaying(false);
  };

  return {
    play,
    stop,
    reset,
    setUrl,
    audioCurrent: audio.current,
    isPlaying,
    isLoading,
  };
};

export default useAudio;
