import React from "react";
import { toast } from "react-toastify";
import { ReactComponent as Share } from "../img/020-share.svg";
import { toEm } from "../utils/css";

const Shared = ({ fileName, title }) => {
  const handleShare = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const response = await fetch(
      `${window.location.origin}${process.env.PUBLIC_URL}/sounds/${fileName}`
    );

    const blob = await response.blob();

    const file = new File([blob], fileName, { type: "audio/mp3" });

    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      try {
        await navigator.share({
          files: [file],
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      toast.error("Le partage de fichier est momentanément indisponible.");
    }
  };

  return (
    <div
      onClick={handleShare}
      style={{
        cursor: "pointer",
        width: toEm(50),
        flex: "0 0 auto",
      }}
    >
      <Share fill={"#fff"} style={{ width: "100%", height: "auto" }} />
    </div>
  );
};

export default Shared;
