import React from "react";
import Sound from "../component/Sound";
import soundList from "../config/soundList.json";
import useAudio from "../hook/useAudio";
import { toEm } from "../utils/css";

import Footer from "../component/Footer";
import Header from "../component/Header";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const image = require("../img/fond-soundbox-fat.jpg");

const Home = () => {
  const { play, stop, setUrl, audioCurrent, isPlaying, isLoading } = useAudio({
    canPlayThrough: () => {
      play();
    },
  });

  const isCurrent = (sound) => {
    return (
      audioCurrent.src &&
      new URL(audioCurrent.src).pathname ===
        `${process.env.PUBLIC_URL}/sounds/${sound.fileName}`
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 1200,
          width: "100%",
          margin: "0 auto",
        }}
      >
        <Header />

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
            flex: 1,
            padding: toEm(10),
          }}
        >
          {soundList.map((sound) => (
            <Sound
              {...sound}
              key={sound.fileName}
              stop={stop}
              setUrl={setUrl}
              isPlaying={isPlaying}
              isLoading={isLoading}
              isCurrent={isCurrent(sound)}
            />
          ))}
        </div>
        <Footer />
        <ToastContainer />
      </div>
    </div>
  );
};

export default Home;
