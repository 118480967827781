import React from "react";
import logo from "../img/soundbox-logo.png";
import { toEm } from "../utils/css";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: toEm(40),
      }}
    >
      <Link to="/">
        <img
          style={{ maxWidth: toEm(300), width: "100%" }}
          src={logo}
          alt="Bande organisée soundbox"
        />
      </Link>
    </div>
  );
};

export default Header;
