import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";

const EC = () => {
  return (
    <div style={{ padding: 20 }}>
      <Header />
      <div
        style={{
          maxWidth: 800,
          width: "100%",
          margin: "20px auto",
          background: "#fff",
          padding: 20,
          boxSizing: "border-box",
        }}
      >
        <h2>ENGAGEMENT DE CONFIDENTIALITÉ APPLE</h2>
        <div>
          La protection de votre vie privée est importante pour Apple. C’est la
          raison pour laquelle nous avons élaboré un Engagement de
          confidentialité qui régit la manière dont nous recueillons, utilisons,
          divulguons, transférons et stockons vos données personnelles. En plus
          de cet Engagement de confidentialité, nous fournissons des
          informations sur les données et la confidentialité, intégrées dans nos
          produits, pour certaines fonctionnalités qui demandent à utiliser vos
          données personnelles.Vous pouvez lire ces informations avant d’activer
          ces fonctionnalités, dans les réglages associés ou en ligne à
          l’adresse apple.com/legal/privacy.
          <br />
          <br />
          Veuillez prendre le temps de vous familiariser avec nos pratiques en
          matière de confidentialité et n’hésitez pas à nous contacter si vous
          avez des questions.
          <br />
          <br />
          <br />
          <strong>Collecte et utilisation des données personnelles</strong>
          <br />
          <br />
          Les données personnelles sont des informations pouvant être utilisées
          pour identifier ou contacter une personne spécifique.
          <br />
          <br />
          Il peut vous être demandé de communiquer vos données personnelles à
          tout moment lorsque vous êtes en contact avec Apple ou une société
          affiliée Apple. Apple et ses sociétés affiliées peuvent s’échanger ces
          données personnelles et les utiliser conformément au présent
          Engagement de confidentialité. Elles peuvent également les associer
          avec d’autres informations afin de fournir et d’améliorer leurs
          produits, services, contenus et publicités. Vous n’êtes pas obligé de
          nous communiquer les données personnelles que nous vous demandons.
          Cependant, si vous choisissez de ne pas le faire, nous ne pourrons pas
          toujours vous fournir nos produits et services, ni même répondre à vos
          questions.
          <br />
          <br />
          Voici quelques exemples de types de données personnelles qu’Apple peut
          recueillir et comment nous pouvons les utiliser :<br />
          <br />
          Données personnelles que nous recueillons
          <br />
          <br />- Lorsque vous créez un identifiant Apple, demandez un crédit
          commercial, achetez un produit, téléchargez une mise à jour
          logicielle, vous inscrivez à une formation auprès d’un magasin Apple
          Store, vous connectez à nos services, nous contactez ou participez à
          une enquête en ligne, nous pouvons collecter tout un ensemble
          d’informations, telles que votre nom, votre adresse postale, votre
          numéro de téléphone, votre adresse e-mail, vos préférences de contact,
          vos identifiants d’appareils, votre adresse IP, vos informations de
          lieu et les données de votre carte bancaire.
          <br />
          <br />- Lorsque vous partagez votre contenu avec votre famille et vos
          amis via des produits Apple, que vous envoyez des chèques cadeaux et
          des produits, ou que vous invitez des tiers à participer à des
          services ou forums Apple, Apple peut collecter les informations que
          vous fournissez sur ces personnes, telles que leur nom, adresse
          postale, adresse e-mail et numéro de téléphone. Apple utilisera ces
          informations pour répondre à vos demandes, pour fournir les produits
          ou services appropriés, ou à des fins de lutte contre la fraude.
          <br />
          <br />- Dans certaines juridictions, nous pouvons vous demander une
          pièce d’identité officielle, mais uniquement dans certains cas, par
          exemple, lors de l’ouverture d’un compte mobile et de l’activation de
          votre appareil, lors de la décision d’étendre un crédit commercial,
          pour gérer des réservations, ou encore si la loi l’exige.
          <br />
          <br />
          Utilisation de vos données personnelles
          <br />
          <br />
          Nous pouvons traiter vos données personnelles dans les objectifs
          décrits dans cet Engagement de confidentialité avec votre
          consentement, pour nous conformer à une obligation légale à laquelle
          Apple est soumise ou lorsque nous estimons que cela est nécessaire
          pour atteindre les objectifs légitimes poursuivis par Apple ou un
          tiers à qui nous pouvons être amenés à divulguer ces données.
          <br />
          <br />- Les données personnelles que nous recueillons nous permettent
          de vous informer des dernières annonces produits, des mises à jour
          logicielles et des événements Apple à venir. Si vous ne souhaitez pas
          faire partie de notre liste de diffusion, vous pouvez décider de vous
          désinscrire à tout moment en modifiant vos préférences.
          <br />
          <br />- Nous utilisons également vos données personnelles pour créer,
          développer, utiliser, livrer et améliorer nos produits, services,
          contenus et publicités, et à des fins de prévention des pertes et de
          lutte contre la fraude. Nous pouvons aussi utiliser vos données
          personnelles dans des objectifs de sécurité des comptes et réseaux,
          notamment afin de protéger nos services pour le bénéfice de tous nos
          utilisateurs. Lorsque nous utilisons vos données à des fins de lutte
          contre la fraude, c’est suite à une transaction en ligne auprès de
          nous. Nous limitons notre utilisation des données à des fins de lutte
          contre la fraude aux données strictement nécessaires et dans le cadre
          de nos intérêts légitimes estimés afin de protéger nos clients et nos
          services. Pour certaines transactions en ligne, nous pouvons également
          vérifier les informations que vous nous avez fournies auprès de
          sources publiquement disponibles.
          <br />
          <br />- Nous pouvons utiliser vos données personnelles, notamment
          votre date de naissance, pour vérifier votre identité, identifier des
          utilisateurs et déterminer les services appropriés. Par exemple, nous
          pouvons déterminer l’âge du détenteur d’un compte Apple grâce à sa
          date de naissance.
          <br />
          <br />- De temps en temps, nous pouvons utiliser vos données
          personnelles pour envoyer des notifications importantes, telles que
          des communications sur les achats, et les modifications apportées à
          nos conditions d’utilisation et politiques. Ces informations étant
          importantes pour vos relations avec Apple, vous ne pouvez pas vous
          opposer à la réception de ces communications.
          <br />
          <br />- Nous pouvons également utiliser les données personnelles à des
          fins internes, par exemple pour des audits, analyses de données et
          recherches dans le but d’améliorer les produits, services et
          communications clients d’Apple.
          <br />
          <br />- Si vous participez à un tirage au sort, un concours ou un
          événement promotionnel similaire, nous pouvons utiliser les
          informations que vous communiquez dans le cadre de la gestion de ces
          programmes.
          <br />
          <br />
          Source de vos données personnelles lorsque nous ne les collectons pas
          auprès de vous
          <br />
          <br />
          Il peut nous arriver de recevoir des données personnelles vous
          concernant par le biais de tiers, si ces personnes partagent leur
          contenu avec vous à l’aide de produits Apple, vous envoient des
          chèques cadeaux et des produits, ou vous invitent à participer à des
          services ou forums Apple. Nous pouvons également vérifier les
          informations que vous nous fournissez lorsque vous créez un
          identifiant Apple avec un tiers, dans un objectif de sécurité et de
          prévention de la fraude.
          <br />
          <br />À des fins de recherche et développement, nous pouvons utiliser
          des ensembles de données tels que ceux contenant des images, voix ou
          autres données pouvant être associées à une personne identifiable.
          Lorsque nous acquérons ce type d’ensemble de données, nous le faisons
          conformément à la loi applicable dans la juridiction dans laquelle se
          trouve l’ensemble de données. Quand nous utilisons ce type d’ensemble
          de données pour la recherche et le développement, nous n’essayons pas
          d’identifier à nouveau les personnes susceptibles d’y figurer.
          <br />
          <br />
          <br />
          <strong>Collecte et utilisation des données non personnelles</strong>
          <br />
          <br />
          Nous collectons également des données dont la forme ne nous permet pas
          de faire un rapprochement direct avec une personne en particulier.
          Nous pouvons recueillir, utiliser, transférer et divulguer des données
          non personnelles à quelque fin que ce soit. Vous trouverez ci-après
          des exemples de données non personnelles que nous collectons et la
          façon dont nous pouvons les utiliser :
          <br />
          <br />- Nous pouvons collecter des informations telles que le métier,
          la langue, le code postal, l’indicatif régional, l’identifiant unique
          de l’appareil, l’URL de référence, le lieu et le fuseau horaire dans
          lesquels un produit Apple est utilisé afin de nous permettre de mieux
          comprendre le comportement du client et d’améliorer nos produits,
          services et publicités.
          <br />
          <br />- Nous pouvons recueillir des données concernant les activités
          du client sur notre site web, les services iCloud, l’iTunes Store,
          l’App Store, le Mac App Store, l’App Store de l’Apple TV, les iBooks
          Stores et à partir de nos autres produits et services. Ces données
          sont rassemblées et utilisées pour nous permettre de fournir des
          informations plus utiles à nos clients et pour savoir quels aspects de
          notre site web, de nos produits et de nos services sont les plus
          populaires. Les informations rassemblées sont considérées comme des
          données non personnelles aux fins du présent Engagement de
          confidentialité.
          <br />
          <br />- Nous pouvons collecter et stocker des informations sur votre
          utilisation de nos services, notamment les recherches que vous
          effectuez. Ces informations peuvent être utilisées pour améliorer la
          pertinence des résultats fournis par nos services. Elles ne sont pas
          associées à votre adresse IP, excepté dans de rares cas afin d’assurer
          la qualité de nos services sur Internet.
          <br />
          <br />- Avec votre consentement explicite, nous pouvons collecter des
          données sur la façon dont vous utilisez votre appareil et vos
          applications afin d’aider les développeurs à améliorer leurs apps.
          <br />
          <br />
          Si nous associons des données non personnelles à des données
          personnelles, les données ainsi combinées sont traitées comme des
          données à caractère personnel tant qu’elles restent associées.
          <br />
          <br />
          <br />
          <strong>Cookies et autres technologies</strong>
          <br />
          <br />
          Les sites web, les services en ligne, les applications interactives,
          les e-mails et les publicités d’Apple peuvent utiliser des « cookies »
          et d’autres technologies, telles que des « pixel tags » et des balises
          web. Ces technologies nous permettent de mieux comprendre le
          comportement des utilisateurs, nous indiquent quelles parties de nos
          sites web sont les plus visitées, et facilitent et mesurent
          l’efficacité des publicités et recherches Internet. Nous traitons les
          données collectées par les cookies et autres technologies comme des
          données non personnelles. Toutefois, si les adresses IP (Internet
          Protocol) ou des identifiants similaires sont considérés comme des
          données personnelles par la loi locale, nous traitons également ces
          identifiants comme des données personnelles. De la même manière, si
          des données non personnelles sont associées à des données
          personnelles, nous traitons les informations ainsi associées comme des
          données personnelles aux fins du présent Engagement de
          confidentialité.
          <br />
          <br />
          Les annonces diffusées par la plateforme publicitaire d’Apple peuvent
          apparaître dans Apple News et dans l’App Store. Si vous ne souhaitez
          pas recevoir des annonces ciblées en fonction de vos centres d’intérêt
          de la part de la plate-forme publicitaire d’Apple, vous pouvez choisir
          d’activer l’option Suivi publicitaire limité, ce qui permettra à votre
          identifiant Apple de ne pas recevoir des annonces de ce type, et ce
          quel que soit l’appareil que vous utilisez. Si vous activez l’option
          Suivi publicitaire limité sur votre appareil mobile, les apps tierces
          ne peuvent pas utiliser l’identifiant publicitaire (un identifiant
          d’appareil non personnel) pour diffuser des annonces ciblées. Vous
          pouvez toujours voir les annonces dans l’App Store ou Apple News, en
          fonction du contexte, tel qu’une demande de recherche ou la chaîne que
          vous lisez. Dans les apps tierces, vous pouvez voir des annonces
          basées sur d’autres informations.
          <br />
          <br />
          Apple et ses partenaires utilisent également des cookies et d’autres
          technologies pour se souvenir de vos données personnelles lorsque vous
          utilisez un site web, des services en ligne et des applications. Dans
          ce cas, notre objectif est de rendre votre visite plus pratique et de
          la personnaliser. Par exemple, le fait de connaitre votre prénom nous
          permettra de vous souhaiter la bienvenue lors de votre prochaine
          visite sur l’Apple Store en ligne. Le fait de connaître votre pays et
          votre langue, et si vous êtes enseignant, le nom de votre école, nous
          permet de vous proposer une visite personnalisée plus efficace. Le
          fait de savoir qu’une personne utilisant votre ordinateur ou appareil
          a acheté un certain produit ou utilisé un service particulier nous
          aide à rendre nos communications publicitaires et électroniques plus
          pertinentes en fonction de vos centres d’intérêt. Enfin, le fait de
          connaître vos coordonnées et vos identifiants matériels, et d’avoir
          des informations à propos de votre ordinateur ou appareil, nous aide à
          personnaliser votre système d’exploitation, à configurer votre service
          iCloud et à vous fournir un meilleur service client.
          <br />
          <br />
          Si vous utilisez le navigateur web Safari et que vous souhaitez
          désactiver les cookies, allez dans les préférences Safari, puis dans
          le volet Confidentialité où une option vous permettra de gérer vos
          préférences. Sur votre appareil mobile Apple, accédez à Réglages >
          Safari, faites défiler l’écran vers le bas pour atteindre la section
          Confidentialité et sécurité, puis appuyez sur « Bloquer les cookies »
          pour gérer vos préférences. Pour les autres navigateurs, veuillez
          consulter votre fournisseur pour savoir comment désactiver les
          cookies. Veuillez noter que certaines fonctionnalités du site web
          Apple ne seront plus disponibles une fois les cookies désactivés.
          <br />
          <br />À l’instar de nombreux sites web, nous collectons certaines
          informations automatiquement et nous les stockons dans des
          historiques. Ces données comprennent les adresses IP (Internet
          Protocol), le type de navigateur et la langue, le fournisseur d’accès
          à Internet (FAI), les sites web et applications de renvoi et de
          sortie, le système d’exploitation, la date et l’heure, ainsi que les
          données relatives à votre parcours sur le site.
          <br />
          <br />
          Nous utilisons ces données pour connaître et analyser les tendances,
          administrer le site, suivre le comportement des utilisateurs sur le
          site, améliorer nos produits et services, et collecter des
          informations démographiques à propos de l’ensemble de nos
          utilisateurs. Apple peut utiliser ces informations pour ses services
          marketing et publicitaires.
          <br />
          <br />
          Dans certains de nos e-mails, nous utilisons des « URL de destination
          » liées au contenu du site web Apple. Lorsque les clients cliquent sur
          l’une de ces URL, ils sont transférés vers un autre serveur web avant
          d’arriver sur la page de destination de notre site web. Nous suivons
          ces données de clic pour nous aider à déterminer l’intérêt porté à
          certains sujets et mesurer l’efficacité de nos communications clients.
          Si vous préférez ne pas être suivi de cette façon, vous ne devez pas
          cliquer sur les liens texte ou graphiques figurant dans les e-mails.
          <br />
          <br />
          Les balises « pixel tags » nous permettent d’envoyer des e-mails dans
          un format que les clients peuvent lire et de savoir si les messages
          ont été ouverts. Nous pouvons utiliser ces informations pour réduire
          ou supprimer les messages adressés aux clients.
          <br />
          <br />
          <br />
          <strong>Divulgation à des tiers</strong>
          <br />
          <br />
          Apple peut parfois mettre certaines données personnelles à la
          disposition de partenaires stratégiques travaillant avec Apple pour la
          fourniture de produits et services, ou aidant Apple à commercialiser
          ses produits auprès des clients. Par exemple, lorsque vous achetez et
          activez votre iPhone, vous autorisez Apple et votre opérateur à
          échanger les informations que vous divulguez pendant la procédure
          d’activation afin d’exécuter le service. À compter de l’activation du
          service, votre compte est régi par les engagements de confidentialité
          respectifs d’Apple et de votre opérateur. Les données personnelles
          sont uniquement partagées par Apple pour fournir ou améliorer des
          produits, services et publicités ; elles ne sont pas partagées avec
          des tiers à des fins marketing.
          <br />
          <br />
          Fournisseurs de services
          <br />
          <br />
          Apple partage des données personnelles avec des sociétés qui
          fournissent des services tels que le traitement d’informations,
          l’extension de crédit, l’exécution de commandes client, la livraison
          de produits, la gestion et le développement de données clients, la
          fourniture d’un service client, l’évaluation de votre intérêt pour nos
          produits et services, et la réalisation d’enquêtes de satisfaction ou
          de développement de clientèle. Ces sociétés sont dans l’obligation de
          protéger vos données et peuvent se trouver dans tout pays dans lequel
          Apple exerce des activités.
          <br />
          <br />
          Autres
          <br />
          <br />
          Apple pourra être amenée, du fait de la loi, dans le cadre d’une
          procédure en justice, d’un litige et/ou d’une requête des autorités
          publiques de votre pays de résidence ou autre, à divulguer vos données
          personnelles. Nous pouvons également divulguer vos données si nous
          pensons qu’à des fins de sécurité nationale, d’application de la loi
          ou autre sujet d’intérêt public, la divulgation est nécessaire ou
          appropriée.
          <br />
          <br />
          Nous pouvons également divulguer des informations vous concernant si
          nous pensons que cette divulgation est raisonnablement nécessaire pour
          faire valoir le respect de nos conditions générales d’utilisation, ou
          pour protéger nos activités ou nos utilisateurs. En outre, en cas de
          restructuration, de fusion ou de vente, nous pouvons transférer toutes
          les données personnelles recueillies au tiers concerné.
          <br />
          <br />
          <br />
          <strong>Protection des données personnelles</strong>
          <br />
          <br />
          Apple prend la sécurité de vos données personnelles très au sérieux.
          Les services en ligne Apple, tels que l’Apple Store en ligne et
          l’iTunes Store, protègent vos données personnelles pendant le transit
          grâce au cryptage TLS (Transport Layer Security). Lorsque vos données
          personnelles sont stockées par Apple, nous utilisons des systèmes
          informatiques à accès limité installés dans des locaux utilisant des
          mesures de sécurité physiques. À l’exception des données Mail iCloud,
          les données iCloud sont stockées dans un format crypté, y compris
          lorsque nous utilisons un stockage tiers.
          <br />
          <br />
          Lorsque vous utilisez certains produits, services ou applications
          Apple, ou que vous participez à un forum, chat ou service de réseau
          social Apple, les données personnelles et les contenus que vous
          partagez sont visibles par les autres utilisateurs qui peuvent les
          lire, les collecter et les utiliser. Vous êtes responsable des données
          personnelles que vous décidez de soumettre de cette façon. Par
          exemple, si vous indiquez votre nom et adresse e-mail sur un forum,
          ces informations deviennent publiques. Vous devez être prudent lorsque
          vous utilisez ces fonctionnalités.
          <br />
          <br />
          Si vous (ou quiconque utilisant le partage familial) vous connectez à
          un appareil appartenant à un tiers, toutes les informations partagées
          avec votre famille, notamment le calendrier, la localisation
          géographique, les photos et les achats iTunes, peuvent être
          téléchargées sur cet appareil tiers, les informations partagées étant
          ainsi divulguées. Pour plus d’informations, consultez le site Partage
          familial.
          <br />
          <br />
          <br />
          <strong>Prise de décision automatisée et profilage</strong>
          <br />
          <br />
          Apple ne prend aucune décision impliquant l’utilisation d’algorithmes
          ou de profilage susceptibles de vous affecter de façon significative.
          <br />
          <br />
          <br />
          <strong>Intégrité et conservation des données personnelles</strong>
          <br />
          <br />
          Apple vous permet de préserver facilement l’exactitude, la mise à jour
          et l’intégrité de vos données personnelles. Nous conservons vos
          données personnelles pendant la durée nécessaire aux finalités
          décrites dans le présent Engagement de confidentialité et nos
          récapitulatifs spécifiques aux services. Pour estimer ces durées, nous
          déterminons avec soin si nous avons besoin de collecter des données
          personnelles et, si nous établissons un tel besoin, nous les
          conservons uniquement pendant la durée la plus courte possible
          nécessaire à la réalisation de l’objectif de la collecte, sauf si une
          durée de conservation plus longue est requise par la loi.
          <br />
          <br />
          <br />
          <strong>Accès aux données personnelles</strong>
          <br />
          <br />
          Vous pouvez nous aider à faire en sorte que vos coordonnées et
          préférences soient exactes, complètes et à jour en vous connectant à
          la page de votre compte Apple. Nous vous fournissons un accès aux
          autres données personnelles que nous détenons, et une copie de
          celles-ci, pour que vous puissiez éventuellement nous demander de les
          corriger si elles sont inexactes ou de les supprimer, à condition
          qu’Apple ne soit pas obligée de les conserver du fait de la loi ou à
          des fins commerciales légitimes. Nous pouvons refuser de traiter les
          demandes futiles/vexatoires, les demandes mettant en péril la
          confidentialité des données de tiers, les demandes qui sont
          extrêmement difficiles à mettre en place ou celles pour lesquelles un
          accès n’est pas imposé autrement par la loi applicable. Nous pouvons
          également refuser certains aspects de demandes de suppression ou
          d’accès si nous pensons que, ce faisant, nous nuirions à notre
          utilisation légitime des données à des fins de lutte contre la fraude
          ou de sécurité, comme nous l’avons vu précédemment. Les outils en
          ligne liés à l’exercice des demandes d’accès, de correction ou de
          suppression sont disponibles, au niveau régional, sur le site
          privacy.apple.com. Quand aucun outil en ligne n’est disponible pour
          les demandes d’accès dans votre région, vous pouvez effectuer une
          demande directement à l’adresse apple.com/legal/privacy/contact.
          <br />
          <br />
          <br />
          <strong>Enfants et éducation</strong>
          <br />
          <br />
          Nous avons bien conscience de l’importance de prendre des précautions
          supplémentaires pour protéger la confidentialité et la sécurité des
          enfants utilisant des produits et services Apple. Les enfants âgés de
          moins de 13 ans, ou de l’âge minimum équivalent dans la juridiction
          applicable, ne sont pas autorisés à créer leur propre identifiant
          Apple, sauf obtention du consentement vérifiable d’un parent, dans le
          cadre du processus de création d’un compte pour enfant au sein du
          partage familial ou s’ils ont obtenu un identifiant Apple géré (le cas
          échéant) via leur établissement scolaire. Par exemple, un parent doit
          lire les Informations sur l’identifiant Apple et le partage familial,
          donner son consentement quant à la collecte, l’utilisation et la
          divulgation des données de son enfant par Apple et accepter les
          conditions générales d’utilisation de l’iTunes Store avant de
          commencer la procédure de création du compte Apple de son enfant. Par
          ailleurs, les établissement scolaires participant à Apple School
          Manager et ayant consulté et accepté la Divulgation des données
          personnelles des identifiants Apple gérés pour élèves peuvent créer
          des identifiants Apple gérés pour les élèves. La Divulgation des
          données personnelles des identifiants Apple gérés pour élèves décrit
          la manière dont Apple gère les informations des élèves et complète
          l’Engagement de confidentialité Apple. Découvrez en plus sur le
          partage familial, les identifiants Apple gérés et les restrictions
          associées aux comptes des enfants.
          <br />
          <br />
          Si nous apprenons que nous avons collecté les données personnelles
          d’un enfant de moins de 13 ans, ou de l’âge minimum équivalent selon
          la juridiction, dans des circonstances autres que celles
          susmentionnées, nous prenons des mesures pour supprimer ces
          informations le plus rapidement possible.
          <br />
          <br />À tout moment, si un parent souhaite accéder aux données
          associées à son compte de partage familial ou à l’identifiant Apple de
          son enfant, les corriger ou les supprimer, il peut nous contacter via
          l’une des options proposées au bas de cette page.
          <br />
          <br />
          <br />
          <strong>Services de géolocalisation</strong>
          <br />
          <br />
          Pour fournir des services de géolocalisation sur les produits Apple,
          Apple et ses partenaires et licenciés peuvent recueillir, utiliser et
          partager des données de localisation précises, notamment la
          localisation géographique en temps réel de votre ordinateur ou
          appareil Apple. Le cas échéant, les services de géolocalisation
          peuvent utiliser le GPS, le Bluetooth et votre adresse IP, ainsi que
          les emplacements des bornes Wi-Fi communautaires et des antennes
          relais, ou encore d’autres technologies afin de déterminer la
          localisation approximative de vos appareils. Sauf consentement de
          votre part, ces données de localisation sont collectées anonymement
          dans un format ne permettant pas de vous identifier. Elles sont
          utilisées par Apple et ses partenaires et licenciés pour fournir et
          améliorer les produits et services de géolocalisation. Par exemple,
          nous pouvons partager la localisation géographique avec des
          fournisseurs d’applications si vous acceptez leurs services de
          localisation.
          <br />
          <br />
          Certains services de géolocalisation proposés par Apple, tels que la
          fonctionnalité « Localiser mon iPhone », ont besoin de vos données
          personnelles pour fonctionner.
          <br />
          <br />
          <br />
          <strong>Sites et services tiers</strong>
          <br />
          <br />
          Les sites web, produits, applications et services Apple peuvent
          contenir des liens vers des sites web, produits et services tiers. Nos
          produits et services peuvent aussi utiliser ou proposer des produits
          et services tiers (par exemple une app iPhone tierce).
          <br />
          <br />
          Les informations collectées par des tiers, qui peuvent comprendre des
          éléments tels que des données de localisation ou des coordonnées, sont
          régies par les pratiques de ces derniers en matière de
          confidentialité. Nous vous invitons à vous renseigner sur ces
          pratiques.
          <br />
          <br />
          Si vous souscrivez un abonnement dans une app tierce ou dans Apple
          News, nous créons un ID d’abonné qui vous est unique, ainsi qu’au
          développeur ou à l’éditeur, et que nous utilisons pour fournir des
          rapports au développeur ou à l’éditeur, qui incluent des informations
          sur l’abonnement auquel vous avez souscrit, ainsi que sur votre pays
          de résidence. Si vous annulez l’ensemble de vos abonnements auprès
          d’un développeur ou d’un éditeur particulier, l’ID d’abonné est
          réinitialisé après 180 jours si vous ne vous réabonnez pas. Ces
          informations sont fournies aux développeurs afin de leur permettre de
          suivre les performances de leurs abonnements.
          <br />
          <br />
          <br />
          <strong>Utilisateurs internationaux</strong>
          <br />
          <br />
          Toutes les informations que vous fournissez peuvent être transférées à
          des entités à travers le monde, ou être accessibles à celles-ci, tel
          que décrit dans le présent Engagement de confidentialité. Les données
          personnelles, relatives aux services Apple, concernant les personnes
          résidant dans un État membre de l’Espace économique européen et en
          Suisse, sont contrôlées par Apple Distribution International en
          Irlande, et traitées pour son compte par Apple Inc. Apple utilise des
          clauses contractuelles types approuvées pour le transfert
          international des données personnelles collectées dans l’Espace
          économique européen et en Suisse. En tant qu’entreprise
          internationale, Apple possède de nombreuses entités juridiques situées
          au sein de différentes juridictions qui sont responsables des données
          personnelles qu’elles collectent et qui sont traitées en leur nom par
          Apple Inc. Par exemple, les informations sur le point de vente au sein
          de nos entités commerciales situées en dehors des États-Unis sont
          contrôlées par les entités commerciales individuelles dans chaque
          pays. Les données personnelles associées à Apple, au magasin en ligne
          et à iTunes peuvent également être contrôlées par des entités
          juridiques en dehors des États-Unis, tel que cela est spécifié dans
          les conditions générales de chaque service.
          <br />
          <br />
          Apple se conforme au système de règles transfrontalières de protection
          de la vie privée (CBPR) de la Coopération économique pour
          l’Asie-Pacifique (APEC). Le système CBPR de l’APEC fournit une
          infrastructure aux organisations pour assurer la protection des
          données personnelles transférées entre les différentes économies des
          pays APEC participants. Pour en savoir plus sur la certification APEC
          et sur la résolution des contentieux, veuillez cliquer sur le sceau de
          confidentialité TRUSTe.
          <br />
          <br />
          <br />
          <strong>Notre engagement à préserver votre confidentialité</strong>
          <br />
          <br />
          Soucieux de préserver la sécurité de vos données personnelles, nous
          communiquons les présentes règles de confidentialité et de sécurité à
          tous les employés Apple et nous appliquons une politique très stricte
          en la matière au sein de notre entreprise.
          <br />
          <br />
          <br />
          <strong>Questions sur la confidentialité</strong>
          <br />
          <br />
          Si vous avez des questions ou des inquiétudes concernant l’Engagement
          de confidentialité Apple ou le traitement des données, vous pouvez
          contacter notre délégué européen à la protection des données. Si vous
          voulez signaler une possible violation des lois locales relatives à la
          confidentialité, veuillez nous contacter. Vous pouvez toujours nous
          contacter par téléphone au numéro de l’assistance Apple correspondant
          à votre pays ou région.
          <br />
          <br />
          Lorsque nous recevons une question relative à la confidentialité ou
          une question portant sur des données personnelles reçues en réponse à
          une demande d’accès/de téléchargement, une équipe dédiée se charge de
          trier les communications et de répondre à votre question ou requête
          spécifique. Lorsque votre question est de nature plus substantielle,
          des informations supplémentaires peuvent vous être demandées. Toutes
          les communications substantielles recevront une réponse. Si la réponse
          que vous avez reçue ne vous satisfait pas, vous pouvez transmettre
          votre réclamation à l’autorité appropriée dans votre juridiction. Si
          vous nous le demandez, nous nous efforcerons de vous donner des
          informations sur les recours possibles applicables à votre situation.
          <br />
          <br />
          Apple peut mettre son Engagement de confidentialité à jour
          périodiquement. En cas de modification substantielle, une notification
          sera affichée sur notre site Internet, accompagnée de l’Engagement de
          confidentialité mis à jour.
          <br />
          <br />
          Apple Inc., One Apple Park Way, Cupertino, Californie, États-Unis,
          95014
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EC;
