import { css, keyframes } from "emotion";
import React from "react";
import Shared from "../component/Shared";
import { ReactComponent as Play } from "../img/008-play.svg";
import { ReactComponent as Stop } from "../img/043-stop.svg";
import { ReactComponent as Loader } from "../img/redo.svg";
import { toEm } from "../utils/css";
import "./sound.css";

const rotation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

const Sound = ({
  fileName,
  title,
  isPlaying,
  stop,
  setUrl,
  isCurrent,
  isLoading,
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isPlaying) {
      stop();
    }

    if (!isCurrent || !isPlaying) {
      setUrl(`${process.env.PUBLIC_URL}/sounds/${fileName}`);
    }
  };

  return (
    <div
      className="sound"
      style={{
        display: "flex",
        height: "auto",
        padding: toEm(10),
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          border: "2px solid #d28d49",
          boxShadow: "rgb(140 140 140) 0px 0px 2px inset",
          cursor: "pointer",
          padding: `${toEm(10)} ${toEm(20)}`,
          width: "100%",
        }}
        onClick={(e) => {
          !(isLoading && isCurrent) && handleOnClick(e);
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            gap: toEm(10),
          }}
        >
          <div
            style={{
              flex: "0 0 auto",
              width: toEm(50),
              height: toEm(50),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxSizing: "border-box",
            }}
          >
            {isPlaying && isCurrent ? (
              <Stop fill="#fff" style={{ width: "100%", height: "auto" }} />
            ) : isLoading && isCurrent ? (
              <Loader
                className={css`
                  animation: ${rotation} 1s ease infinite;
                `}
                fill="#fff"
                style={{ width: "100%", height: "auto" }}
              />
            ) : (
              <Play fill="#fff" style={{ width: "100%", height: "auto" }} />
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: toEm(70),
              boxSizing: "border-box",
              flex: "1 1 auto",
            }}
          >
            <div
              style={{
                fontSize: toEm(14),
                fontFamily: "riftonnormal",
                color: "#fff",
              }}
            >
              {title}
            </div>
          </div>

          <Shared fileName={fileName} title={title} />
        </div>
      </div>
    </div>
  );
};

export default Sound;
